/* marketplace Start*/

.marketplace_wrapper {
  width: 100%;
  float: left;
}
.search_marketpalce {
  width: 100%;
  float: left;
  margin-top: 0px;
  padding-left: 0px;
  position: relative;
}
.search_marketpalce .search_click {
  width: 100%;
  float: left;
  border: 1px solid #C4C2C2;;
  background: #fff;
  outline: 0;
  padding: 10px 15px;
  padding-left: 40px;
  border-radius: 6px;
}
.wrapper_left {
  width: 100%;
  float: left;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background: #CBFFEC 0% 0% no-repeat padding-box;
  border: 1px solid #46B18A;
  border-radius: 6px 6px 0px 0px;
  justify-content: space-between;
}
.wrapper_left_mobile {
  display: none;
}
.dropdown_left {
  width: 100%;
  float: left;
  border-left: 1px solid #DAD9D9;
  border-right: 1px solid #DAD9D9;
  border-bottom: 1px solid #DAD9D9;
  border-radius: 0px 0px 6px 6px;
  padding-top: 12px;
}
.wrapper_left h4 {
  margin-bottom: 0px;
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #494949;
}
.dropdown_left h4 {
  font-family: "Roboto-Medium";
  line-height: 24px;
  margin-top: 15px;
  color: #494949;
  font-size: 16px;
  margin-bottom: 5px;
}
.dropdown_left h4 span {
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #494949;
}
.dropdown_left_show ul {
  padding-left: 10px;
  margin-bottom: 0px;
}
.dropdown_left_show ul li {
  font-family: "Roboto-Regular";
  font-size: 14px;
  list-style: none;
  cursor: pointer;
  color: #494949;
  line-height: 28px;
  width: 100%;
  float: left;
}
.dropdown_left_show ul li span {
  font-family: "Roboto-Regular";
  font-size: 13px;
  color: #494949;
}
.dropdown_left_show .li_active {
  color: #0a89e8;
  font-family: "Roboto-Medium";
}
.dropdown_left_show .li_active span {
  color: #0a89e8;
}
.detail_wrapper {
  width: 100%;
  float: left;
  padding-left: 0px;
}
.detail_wrapper .search_marketpalce {
  width: 100%;
  float: left;
  padding-left: 0px;
}
.detail_wrapper_empty {
  width: 100%;
  float: left;
  font-family: "Roboto-Regular";
  font-size: 15px;
}
.detail_wrapper p {
  line-height: inherit;
}
.bg_marketpalce {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.bg_marketpalce h4 {
  width: 100%;
  float: left;
}
.bg_marketpalce a {
  width: 100%;
  float: left;
}
.bg_marketpalce h4 span {
  width: 100%;
  float: left;
  margin-top: 50px;
}
.bg_marketpalce .cc_feature_apps_header {
  padding: 0px;
}
.bg_marketpalce .cc_feature_apps_header h2 {
  text-align: center;
  font-family: "Roboto-Bold";
  font-size: 35px;
  padding: 0px;
  margin-bottom: 30px;
}
.bg_marketpalce .marketplace_integrations_icons {
  background: #fff;
  padding-top: 0px;
}
.bg_marketpalce .btn-primary {
  background: #2e6dfa;
  color: #fff;
  border-radius: 0px;
  width: 160px;
  text-align: center;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  border: none;
  outline: 0;
  cursor: pointer;
  margin: 0 auto;
}
.search_marketpalce .search_icon {
  position: absolute;
  left: 5px;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react_carosoul {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}
.react_carosoul img.d-block.w-100 {
  width: 100% !important;
  margin: 0 auto;
}
.react_carosoul .carousel-indicators {
  bottom: -5px !important;
}
.react_carosoul .carousel-indicators .active {
  background: #fff !important;
  border: 1px solid #fff;
}
.react_carosoul .carousel-indicators li {
  width: 7px !important;
  height: 7px !important;
  border-radius: 50%;
  border: 1px solid #fff !important;
  background: inherit !important;
  opacity: 1 !important;
  margin-right: 5px;
}
.marketpalce_header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
}
.marketpalce_header_bg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.marketpalce_header_bg img {
  width: 100%;
  float: left;
  height: 360px;
}
.marketpalce_header .main-slider-left {
  padding: 0px;
}
.marketpalce_header .main-slider-left h1 {
  color: #fff;
  margin-bottom: 20px;
}
.marketpalce_header .main-slider-left p {
  color: #fff;
  margin-bottom: 20px;
  padding-right: 10%;
}
.marketpalce_header_bg {
  width: 100%;
  float: left;
  background: url("../images/mp_banner.png") no-repeat;
  background-position: 80% 50%;
  background-size: cover;
  height: 360px;
  margin-bottom: 60px;
}
/* marketplace  end*/
@media only screen and (max-width: 1100px) {
  .marketpalce_header_bg {
    width: 100%;
    float: left;
    background: url("../images/mp_banner.png") no-repeat;
    background-position: 100% 50%;
    background-size: none;
    height: 360px;
    margin-bottom: 60px;
  }
  .marketpalce_header {
    position: absolute;
    top: 24px;
  }
}
@media only screen and (max-width: 1000px) {
  .bg_marketpalce {
    margin-bottom: 40px;
    margin-top: 0px;
  }
  .bg_marketpalce .cogni_fav_apps h1 {
    font-size: 22px !important;
  }
  .bg_marketpalce .marketplace_integrations_icons {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 767px) {
  #_form_22_ ._form_element * {
    font-size: 14px;
  }
  .modal_submit_form .modal-body-header {
    overflow-y: scroll;
    height: calc(100vh - 35vh);
    padding: 28px 0px;
    padding-top: 0px;
  }
  .first_popup_form_modal .modal-body-header {
    overflow-y: scroll;
    height: max-content;
    padding: 28px 0px;
    padding-top: 0px;
  }
  .bg_marketpalce .btn-primary {
    margin-top: 10px;
  }
  .modal-header .close {
    margin: -1rem -1rem -1rem auto;
    padding: 10px;
  }
  .modal_submit_form .modal-header {
    border-bottom: 1px solid #fff !important;
    padding-bottom: 0px;
    position: relative;
    right: 20px;
    z-index: 1;
  }
  .marketpalce_header_bg {
    width: 100%;
    float: left;
    background: url("../images/mob_banner.png") no-repeat;
    background-position: 100% 50%;
    height: 580px;
    margin-bottom: 60px;
    position: relative;
    top: 0px;
    background-size: cover;
  }
  .marketpalce_header .main-slider-left p {
    color: #fff;
    margin-bottom: 12px;
    font-size: 15px;
    padding-right: 15px;
  }
  .assistant_slider.slider_marketpalce {
    padding-top: 0px;
  }
  .marketpalce_header .main-slider-left h1 {
    color: #fff;
    margin-bottom: 12px;
    font-size: 26px;
  }
  .detail_wrapper {
    width: 100%;
    float: left;
    padding-left: 0;
  }
  .search_marketpalce {
    padding-left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wrapper_left_mobile {
    display: block;
    padding-right: 15px;
  }
  .wrapper_left_mobile svg {
    width: 15px;
    height: 15px;
  }
  .wrapper_left {
    border-left: none;
    height: 44px;
  }
  .wrapper_left h4 {
    font-size: 16px;
  }
  .dropdown_left_show ul li {
    font-size: 13px;
  }
  .dropdown_left h4 {
    font-size: 15px;
  }

  .bg_marketpalce h4 span {
    width: 100%;
    margin-top: 30px;
    font-size: 16px;
  }
  .search_marketpalce {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 520px) {
  .search_marketpalce .search_click {
    width: 95%;
  }
  .search_marketpalce .search_icon {
    position: absolute;
    left: 12px;
  }
}

#exampleModalCenter1 .modal_body_right img {
  width: 65%;
  float: left;
  margin: 20px auto;
}
._form-thank-you:after {
  background: url("../images/thankyou.png") no-repeat;
  height: 300px;
}
.modal_submit_form .textarea_market {
  width: 100%;
  float: left;
  border: 1px solid #ccc;
  padding: 0px 10px;
  outline: none;
  resize: none;
  font-size: 15px;
}
._form_content_market {
  width: 100%;
  float: left;
}
#_form_22_submit {
  width: 160px;
  margin-top: 15px;
  height: 38px;
  font-size: 16px;
  cursor: pointer;
}

#_form_22_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: arial, helvetica, sans-serif;
  margin: 0;
}
#_form_22_ * {
  outline: 0;
}
._form_hide {
  display: none;
  visibility: hidden;
}
._form_show {
  display: block;
  visibility: visible;
}
#_form_22_._form-top {
  top: 0;
}
#_form_22_._form-bottom {
  bottom: 0;
}
#_form_22_._form-left {
  left: 0;
}
#_form_22_._form-right {
  right: 0;
}
#_form_22_ input[type="text"],
#_form_22_ input[type="date"],
#_form_22_ textarea {
  padding: 6px;
  height: auto;
  border: #ccc 1px solid;
  border-radius: 0px;
  color: #494949 !important;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#_form_22_ textarea {
  resize: none;
}
#_form_22_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-align: center;
  background: #2e6dfa !important;
  border: 0 !important;
  border-radius: 0px !important;
  color: #fff !important;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#_form_22_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}
#_form_22_ ._close-icon:before {
  position: relative;
}
#_form_22_ ._form-body {
  margin-bottom: 30px;
}
#_form_22_ ._form-image-left {
  width: 150px;
  float: left;
}
#_form_22_ ._form-content-right {
  margin-left: 164px;
}
#_form_22_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}
#_form_22_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}
#_form_22_ ._form-label,
#_form_22_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
#_form_22_._dark ._form-branding {
  color: #333;
}
/* #_form_22_._dark ._form-branding ._logo {
    background-image: url("../images/thankyou.png");
  } */
#_form_22_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}
#_form_22_ ._form_element * {
  font-size: 14px;
  color: #494949;
}
#_form_22_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}
#_form_22_ ._form_element._clear:after {
  clear: left;
}
#_form_22_ ._form_element input[type="text"],
#_form_22_ ._form_element input[type="date"],
#_form_22_ ._form_element select,
#_form_22_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#_form_22_ ._field-wrapper {
  position: relative;
}
#_form_22_ ._inline-style {
  float: left;
}
#_form_22_ ._inline-style input[type="text"] {
  width: 150px;
}
#_form_22_ ._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 20px;
}
#_form_22_ ._form_element img._form-image {
  max-width: 100%;
}
#_form_22_ ._clear-element {
  clear: left;
}
#_form_22_ ._full_width {
  width: 100%;
}
#_form_22_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
#_form_22_ input[type="text"]._has_error,
#_form_22_ textarea._has_error {
  border: #f37c7b 1px solid;
}
#_form_22_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}
#_form_22_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}
#_form_22_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}
#_form_22_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}
#_form_22_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}
#_form_22_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}
#_form_22_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#_form_22_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}
#_form_22_ ._button-wrapper ._error-inner._form_error {
  position: static;
}
#_form_22_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}
#_form_22_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
#_form_22_ ._error-html {
  margin-bottom: 10px;
}
.pika-single {
  z-index: 10000001 !important;
}
@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }
  #_form_22_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  #_form_22_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }
  #_form_22_ ._form-content {
    margin: 0;
    width: 100%;
  }
  #_form_22_ ._form-inner {
    display: block;
    min-width: 100%;
  }
  #_form_22_ ._form-title,
  #_form_22_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
  #_form_22_ ._form-title {
    font-size: 1.2em;
  }
  #_form_22_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }
  #_form_22_ ._form-element,
  #_form_22_ ._inline-style,
  #_form_22_ input[type="text"],
  #_form_22_ label,
  #_form_22_ p,
  #_form_22_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }
  #_form_22_ ._row._checkbox-radio label {
    display: inline;
  }
  #_form_22_ ._row,
  #_form_22_ p,
  #_form_22_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }
  #_form_22_ ._row input[type="checkbox"],
  #_form_22_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }
  #_form_22_ ._row input[type="checkbox"] + span label {
    display: inline;
  }
  #_form_22_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }
  #_form_22_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }
  #_form_22_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }
  #_form_22_ input[type="radio"],
  #_form_22_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }
  #_form_22_ button[type="submit"] {
    font-size: 16px;
  }
  #_form_22_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}
#_form_22_ {
  position: relative;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *zoom: 1;
  background: #fff !important;
  border: 0px solid #b0b0b0 !important;
  width: auto;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #000 !important;
}
#_form_22_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
  color: #494949;
}
#_form_22_:before,
#_form_22_:after {
  content: " ";
  display: table;
}
#_form_22_:after {
  clear: both;
}
#_form_22_._inline-style {
  width: auto;
  display: inline-block;
}
#_form_22_._inline-style input[type="text"],
#_form_22_._inline-style input[type="date"] {
  padding: 10px 12px;
}
#_form_22_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}
#_form_22_._inline-style p {
  margin: 0;
}
#_form_22_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}
#_form_22_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  min-height: 30px;
  padding: 30px 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_22_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}
#exampleModalCenter1 .modal-body {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
}
.show_more_integrations_automate button{
  background: #CBFFEC 0% 0% no-repeat padding-box !important;
  border: 1px solid #46B18A !important;
  border-radius: 30px !important;
  color:#333333 !important;
}
